import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppLayout } from "../components/layouts";

import { Home, Portfolio, NotFound } from "../pages";
import { PortfolioDetail } from "../pages/portfolio/portfolio-detail";

export const BaseRoute: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          {/* <Route path="/contact" element={<Contact />} />*/}
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:id" element={<PortfolioDetail />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
