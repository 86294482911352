import { AiFillGithub, AiFillHtml5, AiOutlineApi } from "react-icons/ai";
import { IoLogoCss3, IoLogoNodejs } from "react-icons/io";
import { IoLogoFirebase, IoLogoJavascript, IoLogoReact } from "react-icons/io5";
import { FaDiscord } from "react-icons/fa";
import { CgArrowsExchangeV } from "react-icons/cg";
import {
  SiAntdesign,
  SiExpress,
  SiIpfs,
  SiMaterialui,
  SiMongodb,
  SiSass,
  SiSolidity,
  SiStyledcomponents,
  SiSwagger,
  SiTailwindcss,
  SiTypescript,
  SiWeb3Dotjs,
} from "react-icons/si";
import { FiFigma } from "react-icons/fi";

export const technologyList = [
  {
    title: "HTML",
    img: <AiFillHtml5 />,
  },
  {
    title: "CSS",
    img: <IoLogoCss3 />,
  },
  {
    title: "SCSS",
    img: <SiSass />,
  },
  {
    title: "JavaScript",
    img: <IoLogoJavascript />,
  },
  {
    title: "TypeScript",
    img: <SiTypescript />,
  },
  {
    title: "React",
    img: <IoLogoReact />,
  },
  {
    title: "React Context API",
    img: <IoLogoReact />,
  },
  {
    title: "NodeJS",
    img: <IoLogoNodejs />,
  },
  {
    title: "Solidity",
    img: <SiSolidity />,
  },
  {
    title: "ExpressJS",
    img: <SiExpress />,
  },
  {
    title: "GIT",
    img: <AiFillGithub />,
  },
  {
    title: "Firebase",
    img: <IoLogoFirebase />,
  },
  {
    title: "MongoDB",
    img: <SiMongodb />,
  },
  {
    title: "IPFS",
    img: <SiIpfs />,
  },
  {
    title: "Tailwind",
    img: <SiTailwindcss />,
  },
  {
    title: "styled-components",
    img: <SiStyledcomponents />,
  },
  {
    title: "REST API",
    img: <AiOutlineApi />,
  },
  {
    title: "Material UI",
    img: <SiMaterialui />,
  },
  {
    title: "ANT Design",
    img: <SiAntdesign />,
  },
  {
    title: "AXIOS",
    img: <CgArrowsExchangeV />,
  },
  {
    title: "DiscordJS",
    img: <FaDiscord />,
  },
  {
    title: "Web3.js",
    img: <SiWeb3Dotjs />,
  },
  {
    title: "Swagger",
    img: <SiSwagger />,
  },
  {
    title: "Figma",
    img: <FiFigma />,
  },
];
