import "./technology.section.css";

import { FC } from "react";
import { technologyList } from "./technology.array";

export const TechnologySection: FC = () => {
  return (
    <section className="technology" id="technology">
      <ul className="technology__equipment__stack">
        {technologyList.map((item, index) => (
          <li key={index} className="technology__equipment__stack__card">
            <span>{item.img}</span>
            <p>{item.title}</p>
          </li>
        ))}
      </ul>
      <div className="technology__equipment">
        <h2>MY EQUIPMENT</h2>
        <h3>
          When developing applications, I reach for proven tools and reliable
          solutions.
        </h3>
      </div>
      <div className="background__svg technology__svg__back"></div>
    </section>
  );
};
