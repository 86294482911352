import "./about.section.css";

import { FC } from "react";
import { BiCodeBlock } from "react-icons/bi";
import { SiBlockchaindotcom, SiDiscord } from "react-icons/si";
import { LinearWaves } from "../../../assets";

export const AboutSection: FC = () => {
  return (
    <section className="about" id="about">
      <div className="background__svg">
        <LinearWaves />
      </div>
      <div className="about__mission">
        <h2>MY MISSION</h2>
        <h3>
          My mission is to create modern applications that are fully tailored to
          the needs of customers.
        </h3>
      </div>
      <div className="about__apps">
        <div className="about__apps__card">
          <span>
            <BiCodeBlock />
          </span>
          <h2>Web Apps</h2>
          <p>
            Do you need a simple website? Or a complex application? Decide
            yourself and I will create it.
          </p>
        </div>
        <div className="about__apps__card">
          <span>
            <SiDiscord />
          </span>
          <h2>Discord</h2>
          <p>
            Do you want to build your own community on a personalized server
            Discord, or do you need a customized BOT?
          </p>
        </div>
        <div className="about__apps__card">
          <span>
            <SiBlockchaindotcom />
          </span>
          <h2>Web3</h2>
          <p>
            Are you interested in the world of blockchain or NFT? I can help you
            create a decentralized application.
          </p>
        </div>
      </div>
    </section>
  );
};
