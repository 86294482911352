import "./portfolio.css";

import { Link } from "react-router-dom";

import { FC } from "react";
import { PortfolioCard } from "../../components/cards/portfolio-card.component";
import { portfolioList } from "../../portfolio/portfolio-db";
import { InterestedSection } from "../sections";

export const Portfolio: FC = () => {
  return (
    <main className="portfolio">
      <div className="portfolio__banner">
        <div className="portfolio__banner__content">
          <h1>Projects</h1>
          <p>
            We have realised many exceptional projects for clients from all over
            the world. You will find more than a dozen that we can tell you
            about here.
          </p>
        </div>
      </div>
      <div className="portfolio__grid">
        {portfolioList.map((item: any, index: number) => (
          <Link key={index} to={item.link}>
            <PortfolioCard
              title={item.title}
              description={item.description}
              image={item.image}
            />
          </Link>
        ))}
      </div>
      <InterestedSection />
    </main>
  );
};
