import { BiCodeBlock, BiPaint } from "react-icons/bi";
import {
  SiBlockchaindotcom,
  SiDiscord,
  SiRobotframework,
} from "react-icons/si";
import { MdOutlineDesignServices } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { FaMedapps } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";

export const ProjectDataCheckbox = [
  {
    icon: <BiCodeBlock />,
    title: "Strona Internetowa",
  },
  {
    icon: <AiOutlineAppstoreAdd />,
    title: "Aplikacja webowa",
  },
  {
    icon: <SiDiscord />,
    title: "Discord Server",
  },
  {
    icon: <SiRobotframework />,
    title: "Discord Bot",
  },
  {
    icon: <MdOutlineDesignServices />,
    title: "Projektowanie",
  },
  {
    icon: <SiBlockchaindotcom />,
    title: "Rozwiązania blockchain",
  },
];

export const MaterialsForProjectCheckbox = [
  {
    icon: <FaMedapps />,
    title: "Pomysł",
  },
  {
    icon: <BiPaint />,
    title: "Projekt Graficzny",
  },
  {
    icon: <IoDocumentTextOutline />,
    title: "Specyfikacja",
  },
];
