import "./portfolio-card.component.css";
import { FC } from "react";

interface PortfolioCardProps {
  title: string;
  description: string;
  image: string;
}

export const PortfolioCard: FC<PortfolioCardProps> = ({
  title,
  description,
  image,
}) => {
  return (
    <div className="portfolio__grid__card">
      <img src={image} alt={title} className="portfolio__grid__card__image" />
      <p className="portfolio__grid__card__description">
        <b>{title}</b> {description}
      </p>
    </div>
  );
};
