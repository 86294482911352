import "./portfolio-detail.css";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import { useParams } from "react-router-dom";
import { InterestedSection } from "../sections";
import CustomImage from "../../components/markdown/customImage.component";

export const PortfolioDetail = () => {
  let { id } = useParams();
  const [markdown, setMarkdown] = useState<any>();

  useEffect(() => {
    import(`../../portfolio/${id}.md`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setMarkdown(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <div className="portfolioDetail">
      <div className="portfolioDetail__content">
        <h1 className="portfolioDetail__title">{id}</h1>
        <ReactMarkdown
          children={markdown}
          components={{
            img: CustomImage,
          }}
        />
      </div>
      <InterestedSection />
    </div>
  );
};
