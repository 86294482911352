export const portfolioList = [
  {
    title: "Sanity Shop",
    link: "sanityshop",
    description: "Fashion E-Commerce",
    date: "2023-10-01",
    image: "portfolio/sanityshop-main.png",
  },
  {
    title: "NextNinja.pl",
    link: "nextninja",
    description: "Blog about programming.",
    date: "2023-10-01",
    image: "portfolio/nextninja-main.png",
  },
  {
    title: "BlockFactory.app",
    link: "blockfactory",
    description: "Create your ERC-20 tokens without coding.",
    date: "2023-10-01",
    image: "portfolio/blockfactory-main.png",
  },
  {
    title: "Exhibit.network",
    link: "exhibit",
    description: "Aptos blockchain NFT Marketplace.",
    date: "2023-10-01",
    image: "portfolio/exhibit-hero.png",
  },
];
