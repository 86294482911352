import { FC } from "react";

export const LinearWaves: FC = () => {
  return (
    <svg
      width="3127"
      height="777"
      viewBox="0 0 3127 777"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3126.25 1C3011.72 79.1812 2891.98 150.793 2763.67 203.39C2722.07 220.444 2679.32 235.549 2634.87 242.307C2544.23 256.087 2452.32 234.567 2360.69 231.679C2244.31 228.008 2127.27 255.607 2024.27 309.744C1946.76 350.483 1859.56 409.573 1771.88 424.402C1692.23 437.873 1606.55 364.578 1536.01 335.98C1463.76 306.691 1382.96 300.453 1306.66 315.519C1266.69 323.414 1227.81 337.15 1191.97 356.55C1157.69 375.106 1127.82 409.639 1087.18 382.499C1073.75 373.527 1064.47 359.703 1052.92 348.402C1012.8 309.132 942.642 306.062 899.248 341.683"
        stroke="url(#paint0_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3121.19 3.45166C3009.27 82.6819 2890.76 155.044 2763.16 207.448C2720.67 224.927 2677.02 239.574 2631.79 247.116C2577.22 256.143 2522.07 252.797 2466.75 248.038C2428.99 244.792 2391.15 240.883 2353.34 239.79C2278.65 237.637 2203.7 246.956 2132.24 266.583C2090.46 278.06 2049.88 293.072 2011.21 311.467C1984.04 324.326 1955.79 339.221 1926.82 353.724C1886.07 374.063 1843.87 393.607 1801 406.123C1785.6 410.633 1770.13 414.239 1754.64 416.607C1690.59 426.357 1622.75 385.066 1561.73 354.69C1545.37 346.508 1529.51 339.11 1514.32 333.689C1451.89 311.202 1383.82 305.56 1317.92 315.354C1306.58 317.038 1295.29 319.18 1284.1 321.775C1244.2 331.022 1205.35 345.962 1169.19 366.036C1147.78 377.928 1127.91 395.628 1106.06 401.22C1092.61 404.665 1078.46 403.456 1062.73 393.618C1048.98 385.022 1039.17 371.739 1027.19 360.824C985.633 322.879 914.911 319.533 870.269 354.696"
        stroke="url(#paint1_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3116.14 5.89697C3006.82 86.1817 2889.55 159.289 2762.65 211.5C2719.26 229.41 2674.71 243.599 2628.7 251.913C2573.43 261.773 2517.46 259.366 2461.27 255.27C2422.9 252.476 2384.42 248.893 2345.98 247.888C2270.04 245.89 2193.85 254.398 2121.2 272.258C2078.73 282.698 2037.49 296.362 1998.14 313.178C1970.44 324.899 1941.72 338.525 1912.32 351.731C1870.93 370.226 1828.15 387.87 1784.51 399.238C1768.87 403.345 1753.14 406.641 1737.37 408.806C1672.07 417.689 1602.91 378.391 1540.78 350.461C1524.16 342.919 1508.03 336.228 1492.61 331.403C1429.34 311.605 1361.1 308.342 1295.29 320.444C1283.97 322.52 1272.71 325.059 1261.53 328.03C1221.69 338.63 1182.88 354.773 1146.41 375.526C1124.8 387.826 1104.6 405.615 1082.34 411.483C1068.62 415.099 1054.24 414.073 1038.26 404.742C1024.19 396.522 1013.86 383.785 1001.46 373.251C958.459 336.631 887.163 333.009 841.284 367.708"
        stroke="url(#paint2_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3111.1 8.34326C3004.38 89.6769 2888.33 163.541 2762.14 215.553C2717.87 233.888 2672.41 247.618 2625.63 256.717C2569.65 267.416 2512.86 265.942 2455.8 262.508C2416.82 260.173 2377.71 256.916 2338.63 255.988C2261.45 254.15 2184 261.84 2110.18 277.928C2067.01 287.336 2025.15 299.758 1985.08 314.885C1956.87 325.54 1927.65 337.819 1897.84 349.733C1855.8 366.384 1812.46 382.129 1768.05 392.343C1752.16 396.047 1736.17 399.034 1720.14 401C1653.57 409.016 1583.03 371.877 1519.85 346.221C1502.96 339.359 1486.59 333.308 1470.92 329.101C1406.84 311.887 1338.4 311.109 1272.69 325.524C1261.39 327.997 1250.14 330.923 1238.99 334.274C1199.21 346.227 1160.44 363.574 1123.66 385.006C1101.85 397.715 1081.32 415.591 1058.65 421.736C1044.66 425.529 1030.04 424.69 1013.82 415.851C999.419 408.011 988.581 395.81 975.745 385.657C931.308 350.362 859.448 346.459 812.322 380.705"
        stroke="url(#paint3_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3106.04 10.7944C3001.93 93.1771 2887.12 167.792 2761.64 219.616C2716.46 238.382 2670.13 251.742 2622.55 261.525C2565.89 273.169 2508.27 272.523 2450.32 269.757C2410.74 267.874 2371 264.948 2331.28 264.104C2252.86 262.425 2174.16 269.304 2099.15 283.62C2055.29 291.99 2012.77 303.098 1972.03 316.613C1943.28 326.153 1913.59 337.134 1883.35 347.762C1840.67 362.569 1796.75 376.415 1751.57 385.475C1735.44 388.776 1719.19 391.454 1702.89 393.215C1635.07 400.365 1563.2 365.268 1498.91 342.003C1481.75 335.792 1465.14 330.409 1449.23 326.821C1384.36 312.191 1315.69 313.896 1250.08 330.619C1238.79 333.485 1227.57 336.803 1216.44 340.535C1176.73 353.84 1137.98 372.39 1100.89 394.496C1078.89 407.614 1058.03 425.573 1034.94 431.999C1020.69 435.969 1005.83 435.317 989.365 426.975C974.636 419.517 963.29 407.856 950.024 398.084C904.145 364.114 831.716 359.935 783.354 393.717"
        stroke="url(#paint4_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3101 13.2397C2999.49 96.6769 2885.91 172.042 2761.13 223.668C2715.06 242.859 2667.84 255.783 2619.47 266.323C2562.12 278.822 2503.66 279.092 2444.85 276.989C2404.66 275.565 2364.29 272.964 2323.93 272.197C2244.26 270.679 2164.31 276.741 2088.12 289.289C2043.56 296.627 2000.38 306.41 1958.97 318.318C1929.69 326.738 1899.55 336.504 1868.86 345.763C1825.58 358.831 1781.03 370.562 1735.1 378.579C1718.72 381.439 1702.22 383.846 1685.64 385.403C1616.55 391.685 1543.36 358.626 1477.97 337.763C1460.55 332.203 1443.68 327.489 1427.54 324.518C1361.87 312.455 1292.99 316.662 1227.46 335.698C1216.19 338.961 1204.99 342.665 1193.88 346.778C1154.23 361.442 1115.51 381.196 1078.11 403.975C1055.92 417.501 1034.72 435.549 1011.22 442.251C996.708 446.397 981.603 445.934 964.902 438.088C949.841 431.011 937.988 419.892 924.291 410.501C876.977 377.856 803.98 373.4 754.369 406.724"
        stroke="url(#paint5_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3095.95 15.6914C2997.05 100.178 2884.7 176.294 2760.62 227.726C2713.66 247.348 2665.54 259.825 2616.39 271.132C2558.34 284.487 2499.06 285.668 2439.37 284.233C2398.58 283.261 2357.57 280.992 2316.58 280.307C2235.67 278.949 2154.47 284.2 2077.08 294.971C2031.84 301.27 1987.99 309.734 1945.91 320.036C1916.1 327.334 1885.49 335.82 1854.38 343.775C1810.45 355.016 1765.33 364.816 1718.64 371.695C1702.01 374.146 1685.24 376.255 1668.4 377.602C1598.05 383.018 1523.54 351.996 1457.03 333.529C1439.36 328.621 1422.25 324.568 1405.84 322.227C1339.4 312.732 1270.28 319.439 1204.85 340.783C1193.59 344.438 1182.42 348.534 1171.32 353.023C1131.74 369.039 1093.05 389.996 1055.34 413.455C1032.96 427.389 1011.43 445.525 987.516 452.504C972.742 456.827 957.377 456.556 940.445 449.202C925.047 442.511 912.691 431.922 898.569 422.912C849.814 391.592 776.248 386.855 725.401 419.726"
        stroke="url(#paint6_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3090.9 18.1367C2994.59 103.672 2883.48 180.539 2760.11 231.778C2712.26 251.825 2663.24 263.855 2613.3 275.929C2554.56 290.134 2494.45 292.238 2433.89 291.465C2392.49 290.951 2350.85 289.008 2309.22 288.401C2227.07 287.203 2144.62 291.636 2066.05 300.64C2020.11 305.907 1975.6 313.046 1932.85 321.741C1902.49 327.919 1871.41 335.124 1839.89 341.782C1795.3 351.19 1749.62 359.062 1702.16 364.804C1685.28 366.847 1668.25 368.652 1651.14 369.795C1579.53 374.344 1503.71 345.354 1436.09 329.288C1418.15 325.026 1400.79 321.642 1384.14 319.925C1316.91 312.985 1247.57 322.205 1182.22 345.862C1170.99 349.909 1159.83 354.403 1148.76 359.267C1109.25 376.635 1070.58 398.796 1032.56 422.928C1009.99 437.271 988.118 455.496 963.798 462.75C948.759 467.244 933.141 467.178 915.982 460.304C900.242 454 887.389 443.946 872.836 435.317C822.646 405.316 748.511 400.309 696.416 432.717"
        stroke="url(#paint7_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3085.85 20.582C2992.15 107.172 2882.27 184.79 2759.6 235.836C2710.86 256.313 2660.95 267.89 2610.23 280.737C2550.79 295.787 2489.85 298.818 2428.42 298.713C2386.41 298.652 2344.14 297.04 2301.87 296.516C2218.47 295.478 2134.77 299.094 2055.02 306.332C2008.39 310.561 1963.21 316.369 1919.79 323.469C1888.89 328.52 1857.35 334.45 1825.4 339.805C1780.18 347.385 1733.92 353.331 1685.69 357.93C1668.57 359.564 1651.28 361.066 1633.9 362.005C1561.03 365.687 1483.9 338.723 1415.15 325.064C1396.96 321.448 1379.36 318.732 1362.46 317.639C1294.45 313.244 1224.87 324.987 1159.62 350.957C1148.41 355.402 1137.28 360.282 1126.21 365.527C1086.76 384.248 1048.13 407.613 1009.8 432.423C987.03 447.175 964.831 465.483 940.097 473.019C924.793 477.689 908.915 477.827 891.535 471.434C875.453 465.521 862.103 455.992 847.12 447.749C795.488 419.074 720.785 413.79 667.454 445.74"
        stroke="url(#paint8_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3080.8 23.0337C2989.71 110.672 2881.06 189.041 2759.09 239.894C2709.45 260.796 2658.64 271.926 2607.13 285.541C2547 301.435 2485.23 305.394 2422.93 305.951C2380.32 306.349 2337.41 305.057 2294.51 304.615C2209.87 303.738 2124.92 306.542 2043.98 312.002C1996.66 315.199 1950.81 319.676 1906.73 325.181C1875.29 329.106 1843.28 333.76 1810.91 337.812C1765.04 343.56 1718.22 347.584 1669.21 351.041C1651.84 352.266 1634.29 353.464 1616.64 354.198C1542.51 357.014 1464.07 332.071 1394.2 320.83C1375.75 317.86 1357.9 315.806 1340.75 315.342C1271.97 313.487 1202.15 327.759 1136.99 356.037C1125.79 360.873 1114.68 366.146 1103.64 371.771C1064.26 391.845 1025.66 416.419 987.013 441.903C964.058 457.064 941.516 475.459 916.374 483.271C900.805 488.119 884.667 488.461 867.061 482.548C850.631 477.027 836.79 468.028 821.376 460.161C768.309 432.81 693.032 427.245 638.458 458.736"
        stroke="url(#paint9_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3075.75 25.48C2987.27 114.167 2879.85 193.293 2758.59 243.947C2708.06 265.28 2656.35 275.957 2604.06 290.344C2543.22 307.084 2480.64 311.97 2417.45 313.19C2374.24 314.04 2330.7 313.079 2287.16 312.721C2201.27 312.003 2115.07 313.99 2032.95 317.684C1984.93 319.843 1938.41 322.984 1893.66 326.898C1861.69 329.692 1829.2 333.076 1796.42 335.831C1749.89 339.74 1702.51 341.849 1652.73 344.168C1635.13 344.985 1617.31 345.879 1599.4 346.409C1524 348.358 1444.26 325.43 1373.26 316.607C1354.55 314.283 1336.45 312.892 1319.05 313.057C1249.51 313.731 1179.44 330.542 1114.37 361.133C1103.19 366.367 1092.1 372.026 1081.08 378.033C1041.77 399.459 1003.2 425.236 964.245 451.394C941.096 466.963 918.223 485.447 892.667 493.535C876.833 498.559 860.436 499.111 842.609 493.673C825.831 488.55 811.482 480.103 795.654 472.589C741.091 446.679 665.306 440.722 609.484 471.755"
        stroke="url(#paint10_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3070.7 27.9253C2984.82 117.667 2878.63 197.538 2758.08 247.999C2706.65 269.757 2654.05 279.981 2600.98 295.142C2539.45 312.72 2476.03 318.539 2411.98 320.422C2368.15 321.73 2323.98 321.095 2279.81 320.814C2192.67 320.256 2105.22 321.427 2021.91 323.353C1973.21 324.48 1926.01 326.279 1880.61 328.604C1848.09 330.266 1815.14 332.375 1781.94 333.832C1734.77 335.902 1686.81 336.101 1636.26 337.272C1618.41 337.686 1600.34 338.271 1582.15 338.597C1505.49 339.679 1424.45 318.76 1352.32 312.361C1333.36 310.677 1315.01 309.949 1297.35 310.755C1227.04 313.946 1156.73 333.308 1091.75 366.206C1080.59 371.832 1069.53 377.889 1058.53 384.271C1019.28 407.05 980.731 434.031 941.472 460.868C918.129 476.845 894.92 495.412 868.955 503.781C852.861 508.982 836.194 509.75 818.152 504.775C801.02 500.055 786.186 492.127 769.927 484.988C713.928 460.404 637.569 454.171 580.511 484.745"
        stroke="url(#paint11_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3065.65 30.3765C2982.37 121.167 2877.41 201.794 2757.57 252.062C2705.25 274.25 2651.75 284.017 2597.89 299.956C2535.67 318.373 2471.42 325.12 2406.49 327.671C2362.07 329.432 2317.27 329.128 2272.45 328.929C2184.07 328.532 2095.37 328.891 2010.88 329.045C1961.48 329.134 1913.61 329.597 1867.54 330.332C1834.48 330.862 1801.06 331.695 1767.44 331.855C1719.62 332.087 1671.1 330.376 1619.78 330.398C1601.69 330.403 1583.35 330.685 1564.9 330.812C1486.98 331.027 1404.64 312.113 1331.38 308.149C1312.16 307.105 1293.57 307.039 1275.66 308.48C1204.59 314.183 1134.03 336.101 1069.14 371.313C1058 377.336 1046.95 383.779 1035.97 390.542C996.786 414.68 958.272 442.858 918.698 470.374C895.163 486.76 871.622 505.415 845.243 514.061C828.884 519.433 811.946 520.421 793.689 515.916C776.21 511.604 760.884 504.19 744.194 497.432C686.76 474.178 609.832 467.658 551.526 497.774"
        stroke="url(#paint12_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3060.6 32.8223C2979.93 124.662 2876.11 205.803 2757.06 256.114C2703.81 278.623 2649.45 288.036 2594.82 304.753C2531.89 324.005 2466.83 331.69 2401.02 334.909C2355.99 337.128 2310.55 337.15 2265.1 337.029C2175.48 336.791 2085.53 336.333 1999.85 334.715C1949.76 333.771 1901.22 332.882 1854.49 332.038C1820.89 331.43 1786.99 330.989 1752.96 329.862C1704.49 328.256 1655.42 324.634 1603.32 323.508C1584.98 323.11 1566.38 323.083 1547.65 323.005C1468.47 322.354 1384.83 305.438 1310.44 303.909C1290.97 303.506 1272.13 304.102 1253.97 306.178C1182.13 314.387 1111.33 338.867 1046.53 376.387C1035.4 382.802 1024.38 389.637 1013.42 396.781C974.304 422.271 935.818 451.653 895.935 479.843C872.206 496.637 848.329 515.381 821.541 524.302C804.918 529.851 787.715 531.06 769.242 527.019C751.41 523.116 735.598 516.214 718.483 509.838C659.614 487.914 582.112 481.113 522.569 510.771"
        stroke="url(#paint13_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3055.55 35.2681C2977.49 128.162 2874.89 210.048 2756.55 260.167C2702.4 283.101 2647.15 292.055 2591.73 309.551C2528.1 329.636 2462.22 338.26 2395.54 342.141C2349.9 344.813 2303.83 345.166 2257.74 345.128C2166.87 345.045 2075.68 343.781 1988.81 340.391C1938.02 338.409 1888.81 336.173 1841.42 333.749C1807.27 331.999 1772.92 330.293 1738.47 327.869C1689.34 324.419 1639.7 318.898 1586.84 316.618C1568.25 315.817 1549.39 315.486 1530.4 315.199C1449.96 313.681 1365.01 298.763 1289.49 299.669C1269.76 299.906 1250.67 301.165 1232.26 303.876C1159.65 314.581 1088.61 341.633 1023.9 381.466C1012.8 388.273 1001.8 395.5 990.85 403.025C951.801 429.868 913.343 460.459 873.151 489.322C849.229 506.52 825.02 525.351 797.818 534.555C780.93 540.28 763.456 541.715 744.774 538.132C726.582 534.643 710.296 528.244 692.745 522.249C632.44 501.65 554.369 494.572 493.579 523.767"
        stroke="url(#paint14_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3050.5 37.7188C2975.04 131.662 2873.68 214.304 2756.05 264.224C2701.01 287.583 2644.85 296.08 2588.66 314.359C2524.33 335.278 2457.62 344.84 2390.07 349.384C2343.82 352.514 2297.12 353.193 2250.4 353.232C2158.29 353.309 2065.84 351.228 1977.79 346.071C1926.31 343.057 1876.42 339.468 1828.37 335.466C1793.68 332.578 1758.86 329.597 1723.99 325.881C1674.21 320.581 1624.01 313.167 1570.37 309.733C1551.54 308.529 1532.42 307.889 1513.16 307.397C1431.46 305.012 1345.21 292.083 1268.56 295.434C1248.58 296.306 1229.24 298.227 1210.58 301.584C1137.19 314.779 1065.91 344.409 1001.29 386.556C990.205 393.761 979.229 401.379 968.303 409.28C929.32 437.475 890.895 469.27 850.388 498.812C826.273 516.418 801.733 535.338 774.117 544.818C756.964 550.719 739.22 552.387 720.322 549.256C701.777 546.181 685.005 540.29 667.023 534.676C605.283 515.402 526.638 508.043 464.611 536.785"
        stroke="url(#paint15_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3045.45 40.165C2972.59 135.157 2872.57 218.788 2755.54 268.277C2699.64 292.177 2642.54 300.099 2585.57 319.157C2520.54 340.91 2453.01 351.41 2384.59 356.617C2337.74 360.2 2290.41 361.204 2243.04 361.331C2149.68 361.569 2055.98 358.67 1966.75 351.742C1914.58 347.689 1864.02 342.748 1815.3 337.172C1780.07 333.136 1744.78 328.891 1709.49 323.889C1659.07 316.745 1608.3 307.431 1553.89 302.843C1534.82 301.237 1515.43 300.287 1495.9 299.591C1412.94 296.34 1325.39 285.397 1247.61 291.2C1227.37 292.712 1207.78 295.291 1188.87 299.288C1114.72 314.967 1043.19 347.181 978.666 391.636C967.602 399.232 956.643 407.243 945.734 415.524C906.817 445.072 868.419 478.07 827.604 508.286C803.295 526.301 778.418 545.309 750.399 555.065C732.987 561.138 714.966 563.042 695.859 560.359C676.961 557.704 659.713 552.277 641.29 547.082C578.148 529.006 498.901 521.498 435.626 549.776"
        stroke="url(#paint16_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3040.4 42.6113C2970.15 138.658 2871.35 223.039 2755.03 272.335C2698.24 296.66 2640.25 304.125 2582.49 323.967C2516.76 346.547 2448.41 357.986 2379.11 363.866C2331.65 367.907 2283.69 369.238 2235.69 369.442C2141.08 369.84 2046.13 366.13 1955.71 357.429C1902.85 352.344 1851.62 346.045 1802.24 338.895C1766.47 333.717 1730.71 328.196 1695 321.907C1643.93 312.914 1592.59 301.706 1537.42 295.965C1518.1 293.955 1498.46 292.696 1478.65 291.797C1394.43 287.678 1305.58 278.712 1226.68 286.971C1206.18 289.119 1186.34 292.354 1167.18 296.997C1092.25 315.155 1020.49 349.959 956.052 396.726C945.005 404.721 934.074 413.118 923.181 421.775C884.331 452.675 845.966 486.877 804.835 517.772C780.334 536.195 755.125 555.286 726.693 565.323C709.015 571.573 690.724 573.715 671.407 571.479C652.155 569.249 634.422 564.313 615.568 559.499C550.991 542.748 471.17 534.964 406.653 562.784"
        stroke="url(#paint17_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3035.36 45.0625C2967.71 142.158 2870.14 227.29 2754.52 276.393C2696.85 301.149 2637.95 308.144 2579.41 328.77C2512.98 352.178 2443.81 364.562 2373.64 371.104C2325.57 375.598 2276.98 377.26 2228.35 377.547C2132.49 378.104 2036.3 373.577 1944.69 363.11C1891.14 356.987 1839.24 349.329 1789.19 340.612C1752.88 334.285 1716.65 327.5 1680.53 319.92C1628.81 309.071 1576.91 295.981 1520.96 289.086C1501.39 286.673 1481.49 285.105 1461.42 284.001C1375.93 279.016 1285.78 272.026 1205.75 282.742C1184.99 285.519 1164.91 289.417 1145.5 294.706C1069.78 315.332 997.796 352.736 933.45 401.811C922.419 410.197 911.51 418.986 900.639 428.024C861.855 460.277 823.518 495.688 782.084 527.251C757.389 546.083 731.844 565.262 703.003 575.575C685.06 582.002 666.504 584.387 646.966 582.592C627.361 580.793 609.142 576.348 589.858 571.915C523.839 556.484 443.455 548.424 377.696 575.791"
        stroke="url(#paint18_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3030.3 47.5083C2965.26 145.653 2868.93 231.536 2754.01 280.445C2695.44 305.626 2635.64 312.157 2576.32 333.573C2509.19 357.81 2439.19 371.137 2368.15 378.342C2319.48 383.294 2270.25 385.282 2220.98 385.646C2123.89 386.364 2026.44 381.025 1933.65 368.78C1879.4 361.625 1826.83 352.603 1776.12 342.318C1739.27 334.843 1702.57 326.788 1666.03 317.927C1613.66 305.229 1561.19 290.251 1504.47 282.196C1484.67 279.38 1464.5 277.503 1444.16 276.194C1357.41 270.342 1265.95 265.329 1184.79 278.508C1163.78 281.92 1143.45 286.474 1123.79 292.409C1047.29 315.503 975.072 355.507 910.82 406.896C899.811 415.68 888.918 424.861 878.07 434.274C839.352 467.885 801.042 504.494 759.299 536.736C734.411 555.976 708.535 575.244 679.279 585.839C661.071 592.442 642.245 595.07 622.497 593.717C602.534 592.348 583.834 588.389 564.119 584.343C496.665 570.237 415.707 561.9 348.705 588.803"
        stroke="url(#paint19_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3025.26 49.9536C2962.82 149.153 2867.71 235.787 2753.51 284.497C2694.04 310.109 2633.34 316.171 2573.25 338.37C2505.41 363.435 2434.6 377.706 2362.68 385.574C2313.4 390.979 2263.55 393.297 2213.64 393.745C2115.29 394.622 2016.6 388.467 1922.63 374.455C1867.68 366.262 1814.46 355.882 1763.07 344.029C1725.69 335.405 1688.52 326.081 1651.55 315.933C1598.54 301.38 1545.5 284.519 1488.01 275.305C1467.95 272.092 1447.53 269.9 1426.92 268.387C1338.9 261.669 1246.14 258.627 1163.86 274.267C1142.59 278.308 1122.01 283.526 1102.1 290.112C1024.82 315.674 952.375 358.278 888.212 411.975C877.22 421.15 866.355 430.724 855.517 440.518C816.86 475.481 778.589 513.294 736.531 546.215C711.455 565.864 685.236 585.214 655.573 596.091C637.1 602.87 618.003 605.747 598.045 604.825C577.728 603.892 558.543 600.419 538.397 596.748C469.502 583.967 387.981 575.354 319.737 601.799"
        stroke="url(#paint20_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3020.21 52.4048C2960.37 152.653 2866.5 240.038 2753 288.561C2692.64 314.597 2631.03 320.19 2570.16 343.184C2501.62 369.072 2429.99 384.293 2357.2 392.828C2307.32 398.691 2256.82 401.336 2206.28 401.86C2106.69 402.898 2006.74 395.925 1911.59 380.147C1855.95 370.921 1802.06 359.173 1750 345.757C1712.08 335.985 1674.44 325.39 1637.05 313.957C1583.4 297.549 1529.78 278.805 1471.53 268.431C1451.23 264.815 1430.54 262.314 1409.66 260.597C1320.39 253.012 1226.31 251.935 1142.91 270.044C1121.38 274.72 1100.54 280.594 1080.39 287.826C1002.32 315.845 929.657 361.061 865.582 417.07C854.606 426.643 843.763 436.603 832.948 446.778C794.357 483.095 756.114 522.111 713.746 555.705C688.477 575.763 661.927 595.202 631.849 606.354C613.117 613.305 593.75 616.435 573.571 615.949C552.906 615.452 533.23 612.46 512.659 609.175C442.323 597.719 360.233 588.831 290.747 614.812"
        stroke="url(#paint21_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3015.15 54.8506C2957.93 156.148 2865.28 244.289 2752.49 292.613C2691.24 319.08 2628.73 324.204 2567.08 347.982C2497.83 374.692 2425.39 390.863 2351.72 400.06C2301.23 406.376 2250.11 409.347 2198.92 409.959C2098.09 411.157 1996.9 403.373 1900.55 385.817C1844.22 375.553 1789.67 362.441 1736.94 347.463C1698.48 336.537 1660.37 324.678 1622.57 311.964C1568.27 293.701 1514.08 273.08 1455.06 261.541C1434.51 257.528 1413.56 254.718 1392.41 252.796C1301.87 244.344 1206.49 245.227 1121.97 265.809C1100.19 271.115 1079.1 277.646 1058.7 285.53C979.831 316.005 906.955 363.832 842.973 422.155C832.02 432.12 821.194 442.472 810.4 453.028C771.876 490.697 733.66 530.917 690.984 565.19C665.521 585.656 638.635 605.183 608.148 616.612C589.151 623.739 569.519 627.123 549.124 627.068C528.106 627.013 507.944 624.501 486.943 621.591C415.171 611.461 332.507 602.29 261.784 627.819"
        stroke="url(#paint22_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3010.11 57.2964C2955.49 159.648 2864.08 248.534 2751.98 296.665C2689.84 323.558 2626.38 328.09 2564 352.78C2493.99 380.18 2420.78 397.427 2346.24 407.293C2295.15 414.067 2243.4 417.363 2191.58 418.053C2089.5 419.411 1987.06 410.81 1889.52 391.487C1832.5 380.191 1777.28 365.71 1723.89 349.169C1684.89 337.089 1646.31 323.966 1608.09 309.965C1553.15 289.847 1498.36 267.465 1438.59 254.646C1417.79 250.268 1396.59 247.11 1375.17 244.984C1283.37 235.665 1186.67 238.514 1101.04 261.569C1078.99 267.504 1057.66 274.698 1037.01 283.228C957.344 316.16 884.248 366.598 820.36 427.229C809.423 437.586 798.624 448.335 787.842 459.266C749.384 498.288 711.201 539.717 668.21 574.664C642.554 595.538 615.337 615.149 584.436 626.858C565.174 634.163 545.276 637.801 524.667 638.176C503.296 638.563 482.648 636.531 461.215 634.002C388.003 625.197 304.776 615.75 232.805 640.821"
        stroke="url(#paint23_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3005.06 59.748C2953.03 163.149 2862.86 252.786 2751.47 300.724C2688.43 328.046 2624.08 332.121 2560.92 357.589C2490.21 385.823 2416.18 404.008 2340.77 414.537C2289.06 421.763 2236.68 425.391 2184.22 426.164C2080.9 427.682 1977.21 418.269 1878.49 397.173C1820.78 384.84 1764.9 368.995 1710.83 350.892C1671.3 337.658 1632.28 323.199 1593.6 307.984C1538.06 285.911 1482.65 261.735 1422.12 247.767C1401.07 242.986 1379.61 239.519 1357.92 237.189C1264.86 227.003 1166.84 231.812 1080.1 257.341C1057.79 263.905 1036.2 271.756 1015.32 280.942C934.852 316.326 861.546 369.381 797.746 432.325C786.826 443.08 776.049 454.215 765.289 465.528C726.897 505.902 688.742 548.534 645.442 584.155C619.593 605.438 592.044 625.136 560.729 637.122C541.202 644.603 521.04 648.495 500.209 649.296C478.49 650.129 457.351 648.567 435.488 646.425C360.84 638.944 277.039 629.216 203.826 653.828"
        stroke="url(#paint24_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M3000.01 62.1934C2950.59 166.643 2861.65 257.036 2750.97 304.781C2687.04 332.529 2621.78 336.145 2557.84 362.391C2486.44 391.464 2411.58 410.583 2335.3 421.78C2282.99 429.465 2229.97 433.418 2176.87 434.268C2072.3 435.946 1967.36 425.716 1867.46 402.854C1809.06 389.488 1752.52 372.268 1697.77 352.608C1657.71 338.221 1618.21 322.503 1579.11 306.001C1522.92 282.079 1466.94 256.009 1405.64 240.888C1384.35 235.704 1362.63 231.927 1340.67 229.393C1246.35 218.341 1147 225.104 1059.15 253.111C1036.59 260.305 1014.74 268.812 993.616 278.651C912.343 316.485 838.833 372.158 775.122 437.409C764.224 448.556 753.463 460.084 742.725 471.777C704.399 513.504 666.272 557.339 622.668 593.639C596.626 615.331 568.74 635.118 537.017 647.38C517.225 655.037 496.797 659.189 475.752 660.414C453.685 661.701 432.054 660.608 409.761 658.841C333.672 652.685 249.307 642.681 174.853 666.835"
        stroke="url(#paint25_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2994.96 64.6387C2948.15 170.143 2860.44 261.282 2750.46 308.833C2685.63 337.012 2619.47 340.164 2554.76 367.194C2482.65 397.101 2406.97 417.158 2329.81 429.017C2276.9 437.16 2223.25 441.439 2169.52 442.372C2063.71 444.211 1957.51 433.163 1856.43 408.529C1797.33 394.125 1740.13 375.542 1684.71 354.32C1644.11 338.778 1604.15 321.807 1564.62 304.008C1507.8 278.242 1451.24 250.273 1389.18 234.003C1367.64 228.416 1345.66 224.33 1323.43 221.592C1227.85 209.672 1127.18 218.395 1038.22 248.876C1015.39 256.699 993.302 265.864 971.93 276.354C889.851 316.634 816.137 374.929 752.514 442.494C741.632 454.038 730.9 465.952 720.178 478.026C681.918 521.111 643.824 566.145 599.906 603.124C573.67 625.224 545.453 645.093 513.316 657.637C493.264 665.465 472.572 669.888 451.305 671.533C428.891 673.267 406.768 672.643 384.044 671.262C306.515 666.432 221.581 656.152 145.885 679.842"
        stroke="url(#paint26_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2989.91 67.0903C2945.7 173.643 2859.22 265.539 2749.95 312.891C2684.23 341.495 2617.17 344.189 2551.68 371.998C2478.87 402.738 2402.36 423.734 2324.33 436.255C2270.81 444.851 2216.53 449.461 2162.16 450.477C2055.1 452.47 1947.66 440.617 1845.39 414.21C1785.6 398.774 1727.78 378.728 1671.64 356.042C1630.54 339.281 1590.08 321.117 1550.13 302.026C1492.65 274.416 1435.53 244.543 1372.69 227.124C1350.91 221.134 1328.67 216.739 1306.17 213.797C1209.33 201.01 1107.27 211.528 1017.27 244.653C994.163 253.061 971.836 262.927 950.222 274.068C867.332 316.8 793.412 377.712 729.889 447.584C719.024 459.52 708.314 471.832 697.609 484.281C659.415 528.719 621.349 574.956 577.121 612.609C550.692 635.112 522.138 655.076 489.593 667.895C469.276 675.9 448.324 680.582 426.831 682.652C404.074 684.844 381.455 684.684 358.301 683.685C279.335 680.179 193.833 669.618 116.894 692.855"
        stroke="url(#paint27_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2984.86 69.5366C2943.26 177.139 2858.01 269.785 2749.44 316.944C2682.83 345.978 2614.87 348.214 2548.6 376.802C2475.09 408.375 2397.76 430.304 2318.86 443.494C2264.73 452.548 2209.82 457.484 2154.81 458.577C2046.5 460.73 1937.81 448.06 1834.35 419.887C1773.87 403.412 1715.39 382.019 1658.58 357.754C1616.95 339.85 1576.01 320.417 1535.64 300.039C1477.53 270.585 1419.82 238.813 1356.22 220.24C1334.19 213.842 1311.69 209.143 1288.92 205.996C1190.82 192.343 1087.45 204.848 996.327 240.419C972.963 249.468 950.36 259.941 928.531 271.772C844.735 316.784 770.711 380.484 707.276 452.67C696.433 465.003 685.739 477.701 675.056 490.526C636.929 536.316 598.895 583.763 554.353 622.089C527.731 645.001 498.846 665.052 465.886 678.148C445.304 686.33 424.093 691.277 402.379 693.761C379.28 696.406 356.164 696.709 332.584 696.091C252.178 693.91 166.108 683.073 87.9263 705.852"
        stroke="url(#paint28_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2979.81 71.9824C2940.81 180.639 2856.79 274.03 2748.93 320.996C2681.43 350.456 2612.57 352.234 2545.51 381.599C2471.3 414.012 2393.16 436.874 2313.37 450.726C2258.64 460.233 2203.09 465.495 2147.45 466.676C2037.9 468.989 1927.96 455.502 1823.32 425.556C1762.15 408.05 1703 385.304 1645.52 359.46C1603.35 340.419 1561.94 319.716 1521.15 298.041C1462.39 266.743 1404.12 233.071 1339.75 213.345C1317.47 206.543 1294.71 201.535 1271.68 198.184C1172.31 183.664 1067.62 198.162 975.387 236.174C951.763 245.857 928.906 256.993 906.834 269.47C822.248 316.983 748.003 383.244 684.657 457.743C673.83 470.469 663.164 483.559 652.498 496.765C614.437 543.908 576.431 592.558 531.579 631.563C504.764 654.883 475.542 675.018 442.174 688.395C421.327 696.753 399.862 701.965 377.922 704.869C354.48 707.972 330.867 708.739 306.857 708.502C225.016 707.641 138.376 696.533 58.9529 718.848"
        stroke="url(#paint29_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2974.76 74.4331C2938.37 184.139 2855.58 278.286 2748.42 325.059C2680.03 354.949 2610.26 356.263 2542.43 386.407C2467.52 419.654 2388.55 443.455 2307.9 457.975C2252.56 467.94 2196.38 473.527 2140.1 474.786C2029.31 477.259 1918.12 462.96 1812.29 431.242C1750.42 412.698 1690.61 388.605 1632.45 361.182C1589.75 340.998 1547.87 319.025 1506.66 296.058C1447.26 262.916 1388.4 227.34 1323.26 206.465C1300.74 199.26 1277.72 193.949 1254.42 190.388C1153.79 175.002 1047.79 191.492 954.435 231.949C930.551 242.274 907.447 254.072 885.126 267.184C799.745 317.197 725.285 386.026 662.027 462.838C651.217 475.956 640.572 489.438 629.928 503.025C591.933 551.52 553.955 601.374 508.794 641.052C481.786 664.781 452.233 685.004 418.451 698.657C397.339 707.193 375.625 712.714 353.448 715.993C329.669 719.598 305.554 720.78 281.113 720.929C197.831 721.392 110.623 710.008 29.957 731.866"
        stroke="url(#paint30_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2969.71 76.8794C2935.92 187.634 2854.37 282.532 2747.92 329.112C2678.62 359.427 2607.97 360.283 2539.35 391.211C2463.73 425.291 2383.95 450.03 2302.42 465.213C2136.36 496.141 1962.63 487.418 1801.26 436.918C1692.7 402.948 1590.45 351.328 1492.17 294.065C1432.13 259.08 1372.71 221.604 1306.8 199.576C1185.63 159.08 1049.83 174.892 933.505 227.71C817.18 280.528 719.129 368.073 639.424 467.918C567.089 558.521 502.12 665.163 394.755 708.91C271.965 758.941 129.283 711.345 1 744.862"
        stroke="url(#paint31_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2713.38 776.977C2714.74 636.995 2665.24 497.063 2576.17 389.069C2550.83 358.345 2521.11 329.294 2483.64 315.818C2375.35 276.884 2259.72 361.994 2168.68 410.319C2058.8 468.63 1950.18 529.741 1835.78 578.949C1771.55 606.575 1696.12 630.022 1633.99 597.957C1571.08 565.483 1547.15 489.869 1521.14 424.022C1490.83 347.292 1449.1 273.379 1387.84 218.126C1326.58 162.873 1243.77 127.992 1161.76 136.985C1097.54 144.03 1039.03 176.614 985.589 212.925C845.187 308.337 725.909 434.649 638.684 580.285"
        stroke="url(#paint32_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2716.89 770.925C2718.09 647.208 2674.57 526.124 2597.41 422.276C2590.24 412.631 2582.86 403.146 2575.17 393.921C2549.87 363.617 2520.4 334.936 2483.45 321.354C2376.6 282.029 2262.74 362.689 2171.82 409.28C2091.22 450.587 2011.18 494.202 1928.3 533.682C1899.55 547.434 1870.55 560.679 1841.18 573.173C1777.47 600.269 1702.93 623.402 1641.27 592.833C1578.83 561.877 1554.11 489.189 1527.39 425.92C1496.24 352.199 1454.03 281.157 1392.88 228.035C1371.59 209.54 1347.77 193.419 1322.32 180.627C1274.71 156.677 1221.41 144.366 1168.45 149.964C1104.83 156.688 1046.74 187.953 993.65 222.608C886.158 292.717 791.171 379.743 712.863 479.991C689.493 509.903 667.614 540.986 647.347 573.156"
        stroke="url(#paint33_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2720.4 764.88C2721.56 645.287 2675.72 530.91 2596.36 426.693C2589.16 417.235 2581.83 407.872 2574.16 398.785C2548.89 368.906 2519.68 340.589 2483.26 326.897C2377.84 287.174 2265.76 363.39 2174.96 408.253C2095.2 447.661 2015.87 490.122 1933.03 528.768C1904.49 542.195 1875.7 555.158 1846.57 567.409C1783.37 593.981 1709.72 616.793 1648.53 587.72C1586.57 558.277 1561.08 488.515 1533.64 427.83C1501.65 357.118 1458.94 288.947 1397.92 237.961C1376.67 220.212 1352.97 204.736 1327.69 192.464C1280.42 169.475 1227.61 157.627 1175.15 162.96C1112.13 169.37 1054.47 199.309 1001.72 232.302C894.864 299.033 800.501 381.278 721.967 476.916C698.531 505.454 676.508 535.173 656.014 566.04"
        stroke="url(#paint34_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2723.91 758.83C2725.03 643.36 2676.9 535.692 2595.32 431.104C2588.09 421.824 2580.8 412.587 2573.16 403.644C2547.93 374.184 2518.97 346.243 2483.09 332.441C2379.1 292.326 2268.81 364.097 2178.12 407.221C2099.22 444.735 2020.48 485.844 1937.78 523.855C1909.42 536.896 1880.88 549.638 1851.98 561.646C1789.29 587.688 1716.53 610.185 1655.81 582.609C1594.33 554.678 1568.05 487.848 1539.9 429.741C1507.06 362.038 1463.88 296.737 1402.98 247.883C1381.76 230.878 1358.18 216.049 1333.07 204.29C1286.13 182.262 1233.82 170.877 1181.86 175.951C1119.44 182.046 1062.21 210.661 1009.79 241.992C903.576 305.35 809.843 382.808 731.082 473.842C707.58 501.004 685.419 529.36 664.699 558.929"
        stroke="url(#paint35_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2727.42 752.784C2728.49 641.438 2678.1 540.467 2594.28 435.52C2587 426.422 2579.76 417.307 2572.14 408.507C2546.94 379.472 2518.25 351.896 2482.89 337.983C2380.34 297.476 2271.86 364.814 2181.25 406.194C2103.23 441.825 2025.14 481.703 1942.51 518.941C1914.34 531.639 1886.03 544.116 1857.36 555.881C1795.19 581.399 1723.33 603.582 1663.07 577.496C1602.06 551.084 1575.01 487.179 1546.14 431.65C1512.46 366.957 1468.79 304.521 1408.01 257.803C1386.83 241.539 1363.37 227.361 1338.43 216.115C1291.82 195.053 1240.01 184.132 1188.54 188.936C1126.72 194.71 1069.92 222.006 1017.84 251.675C912.266 311.654 819.157 384.331 740.164 470.755C716.59 496.543 694.291 523.534 673.344 551.801"
        stroke="url(#paint36_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2730.92 746.734C2731.96 639.513 2679.33 545.232 2593.23 439.933C2585.93 431.011 2578.72 422.028 2571.14 413.366C2545.98 384.751 2517.53 357.544 2482.7 343.527C2381.59 302.628 2274.93 365.539 2184.39 405.168C2107.25 438.933 2029.81 477.569 1947.24 514.034C1919.28 526.39 1891.2 538.602 1862.76 550.124C1801.11 575.111 1730.13 596.98 1670.34 572.39C1609.81 547.491 1581.98 486.518 1552.39 433.567C1517.87 371.877 1473.72 312.317 1413.05 267.736C1391.91 252.217 1368.56 238.685 1343.8 227.953C1297.52 207.851 1246.21 197.395 1195.23 201.938C1134.02 207.399 1077.65 233.374 1025.91 261.382C920.973 317.987 828.487 385.878 749.268 467.697C725.627 492.111 703.185 517.738 682.012 544.702"
        stroke="url(#paint37_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2734.43 740.682C2735.43 637.585 2680.59 549.98 2592.19 444.338C2584.87 435.593 2577.69 426.737 2570.13 418.218C2545.01 390.029 2516.82 363.186 2482.52 349.058C2382.84 307.768 2278.02 366.256 2187.54 404.124C2111.3 436.034 2034.48 473.416 1951.98 509.103C1924.21 521.117 1896.36 533.064 1868.16 544.337C1807.02 568.801 1736.94 590.349 1677.62 567.255C1617.55 543.868 1588.95 485.827 1558.63 435.455C1523.27 376.773 1478.64 320.085 1418.09 277.635C1396.99 262.861 1373.76 249.975 1349.16 239.761C1303.22 220.62 1252.41 210.628 1201.93 214.906C1141.31 220.052 1085.37 244.703 1033.97 271.048C929.674 324.275 837.812 387.384 758.366 464.594C734.654 487.633 712.073 511.897 690.68 537.558"
        stroke="url(#paint38_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2737.94 734.637C2738.9 635.664 2681.88 554.728 2591.15 448.755C2583.8 440.181 2576.65 431.458 2569.12 423.083C2544.04 395.313 2516.09 368.84 2482.33 354.607C2384.08 312.919 2281.11 367.007 2190.68 403.103C2115.35 433.169 2039.16 469.293 1956.71 504.201C1929.14 515.878 1901.51 527.549 1873.56 538.585C1812.93 562.518 1743.73 583.752 1684.88 562.154C1625.29 540.286 1595.91 485.165 1564.88 437.376C1528.68 381.704 1483.57 327.886 1423.14 287.573C1402.07 273.539 1378.96 261.304 1354.53 251.604C1308.93 233.424 1258.61 223.9 1208.62 227.914C1148.61 232.739 1093.1 256.071 1042.03 280.754C938.375 330.608 847.136 388.931 767.459 461.536C743.681 483.2 720.957 506.1 699.337 530.459"
        stroke="url(#paint39_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2741.45 728.586C2742.37 633.737 2683.2 559.453 2590.11 453.165C2582.75 444.762 2575.62 436.178 2568.12 427.94C2543.07 400.595 2515.38 374.493 2482.15 360.149C2385.33 318.069 2284.24 367.757 2193.83 402.069C2119.42 430.309 2043.83 465.157 1961.46 499.281C1934.09 510.616 1906.68 522.022 1878.96 532.815C1818.84 556.224 1750.54 577.137 1692.15 557.041C1633.04 536.685 1602.88 484.496 1571.13 439.286C1534.09 386.622 1488.49 335.675 1428.18 297.493C1407.15 284.204 1384.16 272.616 1359.9 263.429C1314.63 246.215 1264.81 237.15 1215.31 240.904C1155.9 245.414 1100.82 267.421 1050.09 290.448C947.075 336.923 856.461 390.465 776.557 458.46C752.707 478.749 729.845 500.28 707.999 523.347"
        stroke="url(#paint40_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2744.96 722.535C2745.83 631.81 2684.53 564.163 2589.06 457.577C2581.69 449.345 2574.58 440.893 2567.11 432.799C2542.1 405.874 2514.66 380.141 2481.96 365.687C2386.58 323.215 2287.37 368.519 2196.98 401.037C2123.51 427.466 2048.51 461.033 1966.19 494.368C1939.02 505.371 1911.84 516.501 1884.36 527.052C1824.75 549.93 1757.34 570.529 1699.42 551.929C1640.79 533.086 1609.85 483.829 1577.38 441.196C1539.49 391.541 1493.41 343.466 1433.22 307.42C1412.23 294.876 1389.36 283.934 1365.27 275.261C1320.33 259.007 1271.01 250.411 1222.01 253.895C1163.2 258.085 1108.54 278.772 1058.16 300.138C955.782 343.239 865.791 391.994 785.661 455.385C761.745 474.299 738.739 494.467 716.672 516.231"
        stroke="url(#paint41_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2748.47 716.49C2749.31 629.89 2685.9 568.867 2588.02 461.988C2580.63 453.922 2573.55 445.608 2566.1 437.652C2541.13 411.152 2513.95 385.784 2481.77 371.225C2387.82 328.361 2290.51 369.293 2200.11 400C2127.6 424.634 2053.19 456.904 1970.92 489.444C1943.95 500.11 1917 510.97 1889.74 521.277C1830.65 543.631 1764.14 563.909 1706.68 546.806C1648.52 529.476 1616.8 483.15 1583.62 443.096C1544.89 396.45 1498.32 351.245 1438.25 317.33C1417.3 305.527 1394.54 295.23 1370.62 287.076C1326.02 271.783 1277.2 263.651 1228.69 266.875C1170.48 270.75 1116.26 290.112 1066.21 309.816C964.472 349.539 875.105 393.507 794.743 452.294C770.76 469.834 747.611 488.638 725.318 509.098"
        stroke="url(#paint42_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2751.97 710.439C2752.77 627.962 2687.29 573.543 2586.98 466.399C2579.58 458.499 2572.51 450.328 2565.1 442.51C2540.15 416.429 2513.23 391.431 2481.59 376.762C2389.08 333.506 2293.67 370.087 2203.26 398.967C2131.71 421.823 2057.88 452.779 1975.66 484.53C1948.9 494.865 1922.16 505.448 1895.15 515.513C1836.57 537.337 1770.95 557.306 1713.97 541.693C1656.28 525.881 1623.79 482.476 1589.88 445.011C1550.31 401.368 1503.26 359.04 1443.3 327.256C1422.4 316.198 1399.76 306.547 1376 298.912C1331.74 284.58 1283.41 276.911 1235.4 279.87C1177.79 283.431 1123.99 301.468 1074.28 319.516C973.189 355.865 884.447 395.047 803.858 449.223C779.804 465.389 756.517 482.829 734.002 501.987"
        stroke="url(#paint43_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2755.48 704.394C2756.24 626.042 2688.71 578.203 2585.93 470.817C2578.53 463.082 2571.49 455.032 2564.09 447.375C2539.23 421.67 2512.51 397.091 2481.4 382.311C2390.32 338.658 2296.84 370.905 2206.41 397.946C2135.83 419.047 2062.56 448.678 1980.4 479.622C1953.84 489.626 1927.33 499.928 1900.54 509.75C1842.48 531.049 1777.75 550.698 1721.23 536.581C1664.02 522.282 1630.75 481.809 1596.13 446.922C1555.71 406.288 1508.19 366.831 1448.34 337.184C1427.48 326.871 1404.96 317.866 1381.37 310.744C1337.44 297.378 1289.61 290.173 1242.09 292.867C1185.09 296.108 1131.72 312.825 1082.35 329.211C981.896 362.182 893.777 396.583 812.962 446.155C788.841 460.945 765.411 477.022 742.67 494.882"
        stroke="url(#paint44_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2758.99 698.343C2759.71 624.114 2690.16 582.835 2584.89 475.227C2577.48 467.653 2570.46 459.752 2563.08 452.233C2538.27 426.947 2511.79 402.738 2481.21 387.848C2391.57 343.803 2300.03 371.738 2209.55 396.908C2139.96 416.27 2067.25 444.559 1985.13 474.703C1958.79 484.375 1932.49 494.401 1905.94 503.98C1848.39 524.75 1784.55 544.084 1728.5 531.463C1671.76 518.677 1637.72 481.135 1602.38 448.826C1561.12 411.202 1513.11 374.615 1453.38 347.099C1432.55 337.525 1410.16 329.172 1386.73 322.564C1343.14 310.158 1295.8 303.417 1248.78 305.847C1192.37 308.773 1139.44 324.165 1090.4 338.889C990.591 368.487 903.096 398.101 822.05 443.063C797.857 456.478 774.288 471.192 751.321 487.749"
        stroke="url(#paint45_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2762.5 692.291C2763.18 622.187 2691.64 587.444 2583.85 479.638C2576.43 472.223 2569.42 464.467 2562.07 457.091C2537.29 432.225 2511.08 408.385 2481.03 393.391C2392.81 348.953 2303.23 372.593 2212.69 395.881C2144.09 413.525 2071.94 440.456 1989.87 469.789C1963.72 479.13 1937.65 488.88 1911.34 498.216C1854.3 518.461 1791.35 537.475 1735.77 526.35C1679.5 515.082 1644.68 480.466 1608.63 450.736C1566.52 416.12 1518.02 382.404 1458.42 357.025C1437.62 348.197 1415.35 340.49 1392.09 334.394C1348.84 322.95 1302 316.672 1255.47 318.842C1199.66 321.454 1147.15 335.521 1098.45 348.589C999.281 374.807 912.41 399.64 831.137 439.998C806.878 452.039 783.166 465.389 759.978 480.643"
        stroke="url(#paint46_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2766.01 686.246C2766.65 620.266 2693.14 592.032 2582.81 484.049C2575.39 476.795 2568.39 469.181 2561.07 461.949C2536.33 437.503 2510.36 414.033 2480.84 398.928C2394.07 354.099 2306.44 373.471 2215.84 394.843C2148.24 410.787 2076.63 436.349 1994.6 464.87C1968.67 473.885 1942.81 483.353 1916.74 492.446C1860.21 512.161 1798.15 530.861 1743.04 521.232C1687.25 511.477 1651.65 479.787 1614.88 452.641C1571.93 421.034 1522.95 390.189 1463.47 366.94C1442.71 358.858 1420.55 351.796 1397.46 346.22C1354.54 335.736 1308.2 329.928 1262.17 331.833C1206.97 334.124 1154.89 346.872 1106.52 358.278C1007.99 381.123 921.746 401.17 840.246 436.917C815.916 447.578 792.066 459.564 768.651 473.521"
        stroke="url(#paint47_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2769.51 680.195C2770.11 618.339 2694.67 596.587 2581.76 488.46C2574.35 481.361 2567.35 473.896 2560.06 466.807C2535.35 442.786 2509.61 419.742 2480.65 404.471C2395.21 359.421 2309.66 374.382 2218.97 393.816C2152.39 408.087 2081.32 432.258 1999.33 459.956C1973.61 468.646 1947.97 477.833 1922.13 486.683C1866.12 505.873 1804.95 524.252 1750.3 516.12C1694.98 507.877 1658.61 479.119 1621.13 454.551C1577.34 425.953 1527.87 397.979 1468.51 376.867C1447.79 369.524 1425.75 363.114 1402.83 358.052C1360.24 348.534 1314.4 343.184 1268.86 344.823C1214.25 346.8 1162.61 358.223 1114.58 367.967C1016.69 387.434 931.07 402.699 849.339 433.842C824.942 443.128 800.949 453.751 777.308 466.41"
        stroke="url(#paint48_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2773.03 674.15C2773.58 616.418 2696.23 601.12 2580.72 492.878C2573.31 485.932 2566.32 478.623 2559.06 471.672C2534.39 448.07 2508.9 425.396 2480.47 410.02C2396.47 364.572 2312.89 375.321 2222.13 392.795C2156.57 405.416 2086.03 428.178 2004.08 455.054C1978.56 463.424 1953.14 472.323 1927.53 480.93C1872.04 499.591 1811.76 517.655 1757.58 511.025C1702.74 504.3 1665.59 478.468 1627.38 456.478C1582.75 430.889 1532.8 405.786 1473.55 386.805C1452.87 380.207 1430.95 374.444 1408.2 369.894C1365.95 361.337 1320.6 356.457 1275.56 357.831C1221.55 359.487 1170.33 369.596 1122.65 377.679C1025.4 393.772 940.395 404.251 858.443 430.784C833.975 438.696 809.848 447.954 785.982 459.311"
        stroke="url(#paint49_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2776.53 668.1C2777.05 614.487 2697.81 605.62 2579.67 497.289C2572.26 490.499 2565.28 483.338 2558.04 476.531C2533.41 453.354 2508.17 431.044 2480.27 415.558C2397.7 369.718 2316.12 376.288 2225.26 391.758C2160.73 402.75 2090.71 424.088 2008.81 450.13C1983.5 458.179 1958.29 466.786 1932.92 475.156C1877.94 493.292 1818.56 511.042 1764.84 505.902C1710.47 500.69 1672.54 477.789 1633.62 458.378C1588.15 435.792 1537.72 413.559 1478.58 396.715C1457.94 390.863 1436.14 385.745 1413.56 381.71C1371.64 374.113 1326.79 369.696 1282.24 370.806C1228.83 372.147 1178.05 380.931 1130.7 387.352C1034.09 400.066 949.709 405.758 867.531 427.693C842.996 434.23 818.726 442.125 794.633 452.178"
        stroke="url(#paint50_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2780.04 662.048C2780.52 612.559 2699.43 610.08 2578.63 501.694C2571.23 495.053 2564.25 488.052 2557.04 481.383C2532.45 458.626 2507.46 436.686 2480.1 421.095C2398.96 374.858 2319.37 377.281 2228.41 390.725C2164.91 400.11 2095.42 420.013 2013.55 445.216C1988.45 452.945 1963.46 461.265 1938.33 469.386C1883.86 486.992 1825.37 504.427 1772.12 500.784C1718.22 497.085 1679.51 477.11 1639.87 460.282C1593.56 440.705 1542.65 421.343 1483.63 406.636C1463.03 401.524 1441.34 397.057 1418.93 393.54C1377.35 386.904 1333 382.951 1288.95 383.801C1236.14 384.828 1185.78 392.287 1138.78 397.046C1042.81 406.387 959.05 407.293 876.64 424.617C852.039 429.779 827.626 436.311 803.306 445.061"
        stroke="url(#paint51_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2783.55 656.003C2783.98 610.638 2701.07 614.514 2577.59 506.111C2570.19 499.618 2563.21 492.772 2556.03 486.247C2531.47 463.915 2506.74 442.339 2479.91 426.638C2400.21 380.003 2322.61 378.319 2231.55 389.692C2169.08 397.493 2100.12 415.938 2018.28 440.296C1993.39 447.706 1968.62 455.738 1943.72 463.617C1889.77 480.698 1832.16 497.813 1779.39 495.665C1725.97 493.485 1686.48 476.436 1646.12 462.187C1598.96 445.619 1547.57 429.128 1488.67 416.551C1468.11 412.184 1446.54 408.364 1424.3 405.36C1383.05 399.69 1339.2 396.201 1295.64 396.781C1243.44 397.488 1193.51 403.627 1146.84 406.729C1051.51 412.692 968.375 408.816 885.738 421.531C861.066 425.318 836.514 430.486 811.974 437.934"
        stroke="url(#paint52_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2787.05 649.952C2787.45 608.711 2702.73 618.908 2576.54 510.522C2569.16 504.173 2562.17 497.487 2555.02 491.105C2530.49 469.198 2506.06 447.937 2479.72 432.18C2401.54 384.993 2325.86 379.395 2234.69 388.665C2173.25 394.909 2104.82 411.875 2023.01 435.388C1998.33 442.482 1973.78 450.223 1949.12 457.858C1895.67 474.41 1838.96 491.21 1786.65 490.558C1733.71 489.89 1693.44 475.773 1652.37 464.102C1604.38 450.543 1552.49 436.923 1493.71 426.483C1473.19 422.861 1451.74 419.687 1429.67 417.197C1388.75 412.488 1345.4 409.462 1302.33 409.782C1250.72 410.174 1201.22 414.989 1154.89 416.429C1060.2 419.019 977.694 410.356 894.831 418.467C870.092 420.879 845.397 424.678 820.631 430.833"
        stroke="url(#paint53_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2790.57 643.907C2790.92 606.79 2704.42 623.27 2575.5 514.939C2568.12 508.739 2561.14 502.213 2554.02 495.969C2529.52 474.487 2505.34 453.591 2479.53 437.724C2402.79 390.139 2329.11 380.511 2237.84 387.638C2177.43 392.353 2109.53 407.823 2027.76 430.475C2003.29 437.254 1978.95 444.702 1954.52 452.095C1901.59 468.122 1845.77 484.602 1793.92 485.446C1741.45 486.291 1700.41 475.106 1658.62 466.013C1609.78 455.462 1557.41 444.713 1498.76 436.404C1478.26 433.522 1456.94 430.994 1435.03 429.023C1394.45 425.274 1351.59 422.712 1309.02 422.773C1258.02 422.85 1208.95 426.34 1162.96 426.119C1068.91 425.329 987.025 411.886 903.93 415.386C879.119 416.424 854.286 418.859 829.298 423.712"
        stroke="url(#paint54_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2794.07 637.856C2794.39 604.863 2706.13 627.582 2574.46 519.345C2567.09 513.288 2560.1 506.923 2553.01 500.822C2528.55 479.766 2504.63 459.233 2479.35 443.262C2404.04 395.285 2332.35 381.665 2240.98 386.601C2181.6 389.808 2114.23 403.76 2032.49 425.556C2008.23 432.021 1984.1 439.171 1959.91 446.326C1907.49 461.823 1852.57 477.988 1801.19 480.329C1749.19 482.692 1707.38 474.427 1664.87 467.918C1615.18 460.376 1562.33 452.498 1503.8 446.326C1483.34 444.189 1462.14 442.306 1440.4 440.854C1400.15 438.066 1357.79 435.974 1315.72 435.764C1265.32 435.521 1216.68 437.691 1171.02 435.808C1077.61 431.646 996.349 413.416 913.028 412.311C888.151 411.975 863.175 413.046 837.961 416.596"
        stroke="url(#paint55_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2797.58 631.805C2797.86 602.937 2707.88 631.855 2573.42 523.756C2566.06 517.838 2559.05 511.654 2552 505.681C2527.53 485.093 2503.9 464.881 2479.16 448.805C2405.28 400.436 2335.59 382.869 2244.12 385.574C2185.77 387.296 2118.94 399.724 2037.22 420.643C2013.18 426.798 1989.26 433.65 1965.31 440.562C1913.41 455.534 1859.37 471.379 1808.46 475.216C1756.94 479.092 1714.35 473.759 1671.12 469.828C1620.59 465.29 1567.26 460.288 1508.84 456.247C1488.42 454.855 1467.33 453.619 1445.77 452.68C1405.86 450.858 1363.99 449.224 1322.41 448.755C1272.61 448.197 1224.39 449.042 1179.08 445.503C1086.31 437.961 1005.67 414.95 922.126 409.236C897.178 407.525 872.063 407.232 846.623 409.479"
        stroke="url(#paint56_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2801.09 625.759C2801.33 601.015 2709.65 636.089 2572.37 528.172C2565.03 522.397 2558.02 516.38 2551 510.538C2526.57 490.376 2503.19 470.534 2478.98 454.341C2406.54 405.581 2338.82 384.116 2247.27 384.535C2189.93 384.8 2123.65 395.676 2041.96 415.723C2018.13 421.569 1994.43 428.123 1970.71 434.786C1919.32 449.229 1866.18 464.765 1815.73 470.092C1764.69 475.481 1721.31 473.079 1677.37 471.727C1626 470.197 1572.18 468.066 1513.88 466.156C1493.5 465.505 1472.53 464.919 1451.13 464.494C1411.56 463.633 1370.18 462.468 1329.1 461.728C1279.9 460.856 1232.12 460.376 1187.14 455.175C1095.01 444.26 1015 416.463 931.219 406.144C906.204 403.058 880.946 401.402 855.285 402.351"
        stroke="url(#paint57_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2804.6 619.708C2804.8 599.088 2711.45 640.274 2571.34 532.583C2564 526.946 2556.99 521.094 2549.99 515.397C2525.6 495.654 2502.47 476.182 2478.79 459.884C2407.79 410.732 2342.03 385.413 2250.41 383.508C2194.08 382.338 2128.35 391.652 2046.7 410.809C2023.07 416.352 1999.59 422.602 1976.1 429.022C1925.23 442.941 1872.97 458.156 1823 464.985C1772.43 471.887 1728.28 472.417 1683.62 473.642C1631.41 475.122 1577.11 475.862 1518.92 476.088C1498.58 476.182 1477.73 476.243 1456.5 476.331C1417.26 476.43 1376.39 475.729 1335.8 474.73C1287.2 473.537 1239.84 471.738 1195.21 464.875C1103.72 450.581 1024.33 417.997 940.323 403.074C915.242 398.613 889.84 395.593 863.953 395.24"
        stroke="url(#paint58_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2808.11 613.663C2808.27 597.167 2713.27 644.415 2570.29 537C2562.97 531.502 2555.94 525.815 2548.98 520.261C2524.62 500.944 2501.76 481.836 2478.6 465.428C2409.03 415.878 2345.23 386.761 2253.56 382.482C2198.22 379.898 2133.07 387.633 2051.43 405.901C2028.03 411.141 2004.75 417.087 1981.5 423.265C1931.14 436.653 1879.78 451.554 1830.27 459.879C1780.17 468.298 1735.24 471.749 1689.87 475.558C1636.81 480.047 1582.03 483.658 1523.96 486.015C1503.66 486.854 1482.93 487.561 1461.86 488.163C1422.95 489.223 1382.58 488.985 1342.48 487.721C1294.48 486.214 1247.56 483.089 1203.26 474.57C1112.41 456.903 1033.65 419.533 949.416 400C924.263 394.164 898.724 389.78 872.61 388.124"
        stroke="url(#paint59_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2811.62 607.613C2811.74 595.24 2715.11 648.5 2569.25 541.406C2561.94 536.04 2554.92 530.53 2547.98 525.114C2523.65 506.216 2501.04 487.478 2478.42 470.965C2410.28 421.023 2348.41 388.152 2256.71 381.444C2202.35 377.469 2137.78 383.614 2056.17 400.977C2032.97 405.913 2009.92 411.549 1986.9 417.49C1937.05 430.354 1886.58 444.934 1837.54 454.756C1787.92 464.688 1742.22 471.07 1696.12 477.458C1642.22 484.955 1586.96 491.437 1529.01 495.931C1508.75 497.51 1488.13 498.868 1467.24 499.988C1428.67 502.015 1388.78 502.241 1349.18 500.712C1301.79 498.884 1255.3 494.44 1211.33 484.26C1121.12 463.219 1042.98 421.062 958.515 396.925C933.295 389.714 907.612 383.962 881.278 381.008"
        stroke="url(#paint60_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2815.12 601.561C2815.2 593.313 2716.98 652.536 2568.21 545.817C2560.91 540.583 2553.88 535.244 2546.97 529.972C2522.69 511.499 2500.33 493.131 2478.23 476.502C2411.53 426.168 2351.56 389.593 2259.85 380.411C2206.46 375.067 2142.49 379.611 2060.91 396.063C2037.93 400.701 2015.08 406.028 1992.3 411.726C1942.97 424.059 1893.39 438.325 1844.82 449.643C1795.67 461.088 1749.18 470.402 1702.37 479.368C1647.63 489.874 1591.88 499.226 1534.05 505.851C1513.83 508.176 1493.33 510.18 1472.6 511.814C1434.37 514.801 1394.98 515.491 1355.88 513.702C1309.09 511.56 1263.02 505.791 1219.39 493.948C1129.83 469.529 1052.31 422.591 967.619 393.844C942.328 385.259 916.506 378.142 889.945 373.891"
        stroke="url(#paint61_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2818.63 595.516C2818.67 591.392 2718.87 656.522 2567.16 550.234C2559.87 545.132 2552.84 539.97 2545.96 534.836C2521.71 516.783 2499.6 498.785 2478.04 482.051C2412.77 431.325 2354.67 391.094 2262.98 379.384C2210.54 372.688 2147.21 375.719 2065.63 391.149C2042.87 395.516 2020.23 400.507 1997.69 405.962C1948.87 417.771 1900.18 431.717 1852.08 444.531C1803.4 457.494 1756.14 469.734 1708.61 481.278C1653.03 494.793 1596.8 507.016 1539.08 515.778C1518.9 518.848 1498.52 521.498 1477.96 523.645C1440.06 527.593 1401.17 528.746 1362.56 526.693C1316.37 524.236 1270.73 517.147 1227.44 503.643C1138.52 475.851 1061.62 424.126 976.706 390.774C951.349 380.814 925.384 372.334 898.602 366.78"
        stroke="url(#paint62_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <path
        d="M2822.14 589.465C2822.14 589.465 2720.79 660.453 2566.12 554.645C2411.45 448.837 2358.2 334.676 2070.38 386.236C1871.48 421.868 1683.85 509.263 1483.34 535.471C1400.84 546.253 1314.37 541.174 1235.51 513.332C1147.23 482.161 1070.96 425.655 985.81 387.693C960.381 376.359 934.284 366.515 907.27 359.658"
        stroke="url(#paint63_linear_1_848)"
        stroke-width="0.552088"
        stroke-miterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_848"
          x1="2012.75"
          y1="1"
          x2="2012.75"
          y2="426.038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_848"
          x1="1995.73"
          y1="3.45166"
          x2="1995.73"
          y2="418.063"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_848"
          x1="1978.71"
          y1="5.89697"
          x2="1978.71"
          y2="413.418"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_848"
          x1="1961.71"
          y1="8.34326"
          x2="1961.71"
          y2="423.874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_848"
          x1="1944.7"
          y1="10.7944"
          x2="1944.7"
          y2="434.354"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1_848"
          x1="1927.68"
          y1="13.2397"
          x2="1927.68"
          y2="444.836"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1_848"
          x1="1910.67"
          y1="15.6914"
          x2="1910.67"
          y2="455.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1_848"
          x1="1893.66"
          y1="18.1367"
          x2="1893.66"
          y2="465.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1_848"
          x1="1876.65"
          y1="20.582"
          x2="1876.65"
          y2="476.393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1_848"
          x1="1859.63"
          y1="23.0337"
          x2="1859.63"
          y2="486.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1_848"
          x1="1842.62"
          y1="25.48"
          x2="1842.62"
          y2="497.532"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1_848"
          x1="1825.61"
          y1="27.9253"
          x2="1825.61"
          y2="508.122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1_848"
          x1="1808.59"
          y1="30.3765"
          x2="1808.59"
          y2="518.769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1_848"
          x1="1791.59"
          y1="32.8223"
          x2="1791.59"
          y2="529.406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1_848"
          x1="1774.56"
          y1="35.2681"
          x2="1774.56"
          y2="540.085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1_848"
          x1="1757.56"
          y1="37.7187"
          x2="1757.56"
          y2="550.808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1_848"
          x1="1740.54"
          y1="40.165"
          x2="1740.54"
          y2="561.547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1_848"
          x1="1723.53"
          y1="42.6113"
          x2="1723.53"
          y2="572.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1_848"
          x1="1706.53"
          y1="45.0625"
          x2="1706.53"
          y2="583.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1_848"
          x1="1689.5"
          y1="47.5083"
          x2="1689.5"
          y2="594.062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1_848"
          x1="1672.5"
          y1="49.9536"
          x2="1672.5"
          y2="604.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1_848"
          x1="1655.48"
          y1="52.4048"
          x2="1655.48"
          y2="615.998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1_848"
          x1="1638.47"
          y1="54.8506"
          x2="1638.47"
          y2="627.819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1_848"
          x1="1621.46"
          y1="57.2964"
          x2="1621.46"
          y2="640.821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1_848"
          x1="1604.44"
          y1="59.748"
          x2="1604.44"
          y2="653.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1_848"
          x1="1587.43"
          y1="62.1934"
          x2="1587.43"
          y2="666.835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1_848"
          x1="1570.42"
          y1="64.6387"
          x2="1570.42"
          y2="679.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_1_848"
          x1="1553.4"
          y1="67.0903"
          x2="1553.4"
          y2="692.855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_1_848"
          x1="1536.39"
          y1="69.5366"
          x2="1536.39"
          y2="705.852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_1_848"
          x1="1519.38"
          y1="71.9824"
          x2="1519.38"
          y2="718.848"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_1_848"
          x1="1502.36"
          y1="74.4331"
          x2="1502.36"
          y2="731.866"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_1_848"
          x1="1485.36"
          y1="76.8794"
          x2="1485.36"
          y2="744.862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_1_848"
          x1="1676.05"
          y1="135.576"
          x2="1676.05"
          y2="776.977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_1_848"
          x1="1682.13"
          y1="148.626"
          x2="1682.13"
          y2="770.925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_1_848"
          x1="1688.22"
          y1="161.695"
          x2="1688.22"
          y2="764.88"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_1_848"
          x1="1694.31"
          y1="174.756"
          x2="1694.31"
          y2="758.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_1_848"
          x1="1700.39"
          y1="187.814"
          x2="1700.39"
          y2="752.784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_1_848"
          x1="1706.48"
          y1="200.887"
          x2="1706.48"
          y2="746.734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_1_848"
          x1="1712.56"
          y1="213.926"
          x2="1712.56"
          y2="740.682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_1_848"
          x1="1718.64"
          y1="227.005"
          x2="1718.64"
          y2="734.637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_1_848"
          x1="1724.73"
          y1="240.064"
          x2="1724.73"
          y2="728.586"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_1_848"
          x1="1730.82"
          y1="253.127"
          x2="1730.82"
          y2="722.535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_1_848"
          x1="1736.9"
          y1="266.176"
          x2="1736.9"
          y2="716.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_1_848"
          x1="1742.99"
          y1="279.241"
          x2="1742.99"
          y2="710.439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_1_848"
          x1="1749.08"
          y1="292.308"
          x2="1749.08"
          y2="704.394"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_1_848"
          x1="1755.16"
          y1="305.355"
          x2="1755.16"
          y2="698.343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_1_848"
          x1="1761.24"
          y1="318.417"
          x2="1761.24"
          y2="692.291"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_1_848"
          x1="1767.33"
          y1="331.474"
          x2="1767.33"
          y2="686.246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_1_848"
          x1="1773.41"
          y1="344.53"
          x2="1773.41"
          y2="680.195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_1_848"
          x1="1779.5"
          y1="357.601"
          x2="1779.5"
          y2="674.15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_1_848"
          x1="1785.58"
          y1="370.636"
          x2="1785.58"
          y2="668.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_1_848"
          x1="1791.68"
          y1="382.234"
          x2="1791.68"
          y2="662.048"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_1_848"
          x1="1797.76"
          y1="383.828"
          x2="1797.76"
          y2="656.003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_1_848"
          x1="1803.84"
          y1="384.917"
          x2="1803.84"
          y2="649.952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_1_848"
          x1="1809.93"
          y1="385.536"
          x2="1809.93"
          y2="643.907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_1_848"
          x1="1816.02"
          y1="385.65"
          x2="1816.02"
          y2="637.856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_1_848"
          x1="1822.1"
          y1="385.306"
          x2="1822.1"
          y2="631.805"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_1_848"
          x1="1828.19"
          y1="384.529"
          x2="1828.19"
          y2="625.759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_1_848"
          x1="1834.28"
          y1="383.41"
          x2="1834.28"
          y2="619.708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_1_848"
          x1="1840.36"
          y1="381.997"
          x2="1840.36"
          y2="613.663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_1_848"
          x1="1846.45"
          y1="380.281"
          x2="1846.45"
          y2="610.206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_1_848"
          x1="1852.53"
          y1="373.891"
          x2="1852.53"
          y2="610.627"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_1_848"
          x1="1858.61"
          y1="366.78"
          x2="1858.61"
          y2="611.524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_1_848"
          x1="1864.7"
          y1="359.658"
          x2="1864.7"
          y2="612.771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7411C1" />
          <stop offset="1" stopColor="#D30DA8" stop-opacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  );
};
