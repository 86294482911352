import "./not-found.pages.css";

import { FC } from "react";

export const NotFound: FC = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};
