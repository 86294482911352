import { FC, ReactNode } from "react";
import { Link } from "react-scroll";

interface ScrollToProps {
  activeClass?: string;
  spy?: boolean;
  smooth?: boolean;
  offset?: number;
  duration?: number;
  to?: string;
  className?: string;
  children?: ReactNode;
}

export const ScrollTo: FC<ScrollToProps> = ({
  activeClass,
  spy,
  smooth,
  offset,
  duration,
  to,
  className,
  children,
}) => {
  return (
    <Link
      className={className}
      style={{ cursor: "pointer" }}
      activeClass={activeClass}
      spy={spy}
      smooth={smooth}
      offset={offset}
      duration={duration}
      to={to ? to : ""}
    >
      {children}
    </Link>
  );
};
