import "./social-navigation.component.css";

import { FaGithub, FaLinkedin, FaMedium } from "react-icons/fa";
import { SiBuymeacoffee } from "react-icons/si";

export const SocialNavigation = () => {
  return (
    <div className="socialNavigation">
      <a href="https://github.com/Lukols-Dev" target="_blank" rel="noreferrer">
        <FaGithub />
      </a>
      <a
        href="https://www.linkedin.com/in/lukols/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedin />
      </a>
      <a href="https://medium.com/@lukols.dev" target="_blank" rel="noreferrer">
        <FaMedium />
      </a>
      <a
        href="https://www.buymeacoffee.com/lukolsdev"
        target="_blank"
        rel="noreferrer"
      >
        <SiBuymeacoffee />
      </a>
    </div>
  );
};
