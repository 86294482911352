import "./home.pages.css";

import { FC } from "react";
import { HomeImage } from "../../assets";
import {
  AboutSection,
  InfoSection,
  InterestedSection,
  TechnologySection,
} from "../sections";
import { SocialNavigation } from "../../components/navigation";

export const Home: FC = () => {
  return (
    <main className="home__container">
      <SocialNavigation />
      <section className="home">
        <div className="home__description">
          <h1>
            Modern code, <br /> tailored to your needs
          </h1>
          <h3>
            I develop dedicated web applications, discord bots and discord
            servers
          </h3>
          <a href="mailto:lukols.dev@gmail.com?subject = Contact&body = Contact Message">
            Quote project
          </a>
        </div>
        <div className="home__graphic">
          <HomeImage />
        </div>
        <span className="home__graphic__light-circle">
          <svg
            width="1000"
            height="1000"
            viewBox="0 0 431 415"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_33_22)">
              <ellipse
                cx="215.5"
                cy="207.5"
                rx="135.5"
                ry="127.5"
                fill="#31267A"
                fill-opacity="0.4"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_33_22"
                x="0"
                y="0"
                width="431"
                height="415"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="50"
                  result="effect1_foregroundBlur_33_22"
                />
              </filter>
            </defs>
          </svg>
        </span>
      </section>
      {/* <section className="company__colab"></section> */}
      <AboutSection />
      <TechnologySection />
      <InfoSection />
      <InterestedSection />
    </main>
  );
};
