import { FC } from "react";

export const HomeImage: FC = () => {
  return (
    <svg
      width="681"
      height="741"
      viewBox="0 0 681 841"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.07"
        d="M680.907 422.994C669.056 416.077 660.471 415.89 655.152 422.434C649.833 428.883 647.221 439.819 647.221 455.242V538.338C647.221 554.135 645.728 567.221 642.835 577.597C639.942 588.346 634.997 596.01 628.185 600.497C621.56 605.077 612.601 606.292 601.217 604.143C590.113 602.086 576.209 596.291 559.6 586.663L551.668 582.083V516.653L561.559 522.355C573.41 529.272 581.342 530.113 585.354 525.065C589.553 520.205 591.699 510.016 591.699 494.594V421.405C591.699 402.337 593.566 387.288 597.298 376.165C601.031 365.042 608.402 358.031 619.507 354.853C608.402 338.87 601.031 323.26 597.298 307.931C593.566 292.601 591.793 275.402 591.793 256.334V183.146C591.793 167.723 589.646 155.198 585.447 145.383C581.528 135.756 573.597 127.53 561.652 120.613L551.761 114.911V49.4813L559.693 54.0614C576.303 63.689 590.206 73.8774 601.311 84.7201C612.695 95.6563 621.653 107.34 628.278 119.585C635.183 132.017 640.036 145.29 642.928 159.404C645.821 173.144 647.314 188.006 647.314 203.71V286.806C647.314 302.229 649.927 316.25 655.246 328.775C660.564 341.394 669.149 351.115 681 357.938L680.907 422.994Z"
        fill="url(#paint0_linear_299_1525)"
      />
      <path
        opacity="0.07"
        d="M379.972 182.305C391.823 189.222 400.407 189.408 405.726 182.865C411.045 176.416 413.658 165.48 413.658 150.057V66.9605C413.658 51.1638 415.151 38.0777 418.043 27.7024C420.936 16.9531 425.695 9.19495 432.32 4.61483C439.226 0.12819 448.184 -1.08694 459.288 0.969436C470.672 3.11929 484.669 9.00801 501.279 18.6356L509.21 23.2157V88.6459L499.319 82.9441C487.469 76.0272 479.35 75.0925 475.151 79.9531C471.232 85.0005 469.179 95.1889 469.179 110.612V183.8C469.179 202.868 467.313 217.917 463.58 229.04C459.848 240.07 452.476 247.174 441.372 250.352C452.476 266.336 459.848 281.945 463.58 297.275C467.313 312.604 469.086 329.803 469.086 348.871V422.06C469.086 437.482 471.045 450.008 475.058 459.635C479.257 469.45 487.375 477.769 499.226 484.592L509.117 490.294V555.724L501.186 551.144C484.576 541.517 470.579 531.235 459.195 520.298C448.09 509.456 439.132 497.865 432.227 485.434C425.602 473.189 420.843 460.009 417.95 445.801C415.057 432.061 413.564 417.199 413.564 401.496V318.399C413.564 302.977 410.952 288.956 405.633 276.431C400.314 263.812 391.729 254.091 379.878 247.267L379.972 182.305Z"
        fill="url(#paint1_linear_299_1525)"
      />
      <path
        opacity="0.4"
        d="M643.582 451.036C631.731 444.119 623.146 443.932 617.827 450.475C612.508 456.925 609.896 467.861 609.896 483.284V566.38C609.896 582.177 608.403 595.263 605.51 605.638C602.617 616.387 597.672 624.052 590.86 628.539C584.234 633.119 575.276 634.334 563.892 632.184C552.788 630.128 538.884 624.332 522.274 614.705L514.343 610.125V544.695L524.234 550.396C536.085 557.313 544.016 558.155 548.029 553.107C552.228 548.247 554.374 538.058 554.374 522.635V449.447C554.374 430.379 556.24 415.33 559.973 404.207C563.706 393.083 571.077 386.073 582.182 382.895C571.077 366.911 563.706 351.302 559.973 335.972C556.24 320.643 554.467 303.444 554.467 284.376V211.187C554.467 195.765 552.321 183.239 548.122 173.425C544.203 163.797 536.271 155.572 524.327 148.655L514.436 142.953V77.5228L522.368 82.1029C538.977 91.7305 552.881 101.919 563.985 112.762C575.37 123.698 584.328 135.382 590.953 147.627C597.858 160.058 602.71 173.331 605.603 187.446C608.496 201.186 609.989 216.048 609.989 231.751V314.848C609.989 330.27 612.602 344.291 617.92 356.816C623.239 369.435 631.824 379.156 643.675 385.98L643.582 451.036Z"
        fill="url(#paint2_linear_299_1525)"
      />
      <path
        opacity="0.4"
        d="M342.646 210.346C354.497 217.263 363.082 217.45 368.401 210.907C373.719 204.457 376.332 193.521 376.332 178.098V95.002C376.332 79.2053 377.825 66.1192 380.718 55.7439C383.611 44.9946 388.37 37.2365 394.995 32.6564C401.9 28.1697 410.858 26.9546 421.962 29.011C433.347 31.1608 447.344 37.0495 463.953 46.6771L471.885 51.2572V116.687L461.994 110.986C450.143 104.069 442.025 103.134 437.826 107.995C433.906 113.042 431.854 123.23 431.854 138.653V211.842C431.854 230.91 429.987 245.959 426.255 257.082C422.522 268.112 415.15 275.216 404.046 278.394C415.15 294.377 422.522 309.987 426.255 325.316C429.987 340.646 431.76 357.845 431.76 376.913V450.101C431.76 465.524 433.72 478.049 437.732 487.677C441.931 497.491 450.05 505.81 461.9 512.634L471.792 518.336V583.766L463.86 579.186C447.25 569.558 433.253 559.276 421.869 548.34C410.765 537.497 401.807 525.907 394.901 513.475C388.276 501.23 383.517 488.051 380.625 473.843C377.732 460.103 376.239 445.241 376.239 429.537V346.441C376.239 331.018 373.626 316.997 368.307 304.472C362.988 291.854 354.404 282.132 342.553 275.309L342.646 210.346Z"
        fill="url(#paint3_linear_299_1525)"
      />
      <path
        opacity="0.15"
        d="M135.491 11.4383L294.963 103.695L294.87 486.275L135.397 394.018L135.491 11.4383Z"
        fill="url(#paint4_linear_299_1525)"
      />
      <path
        d="M105.537 70.232L232.35 143.607V178.846L105.537 105.471V70.232Z"
        fill="#7411C1"
      />
      <path
        opacity="0.15"
        d="M155.18 223.152L277.047 293.629V348.404L155.18 277.926V223.152Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M155.087 156.88L277.14 227.451V240.164L155.087 169.686V156.88Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M155.087 181.744L260.624 242.781V255.493L155.087 194.456V181.744Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M135.491 11.4383L294.963 103.695V155.011L135.491 62.7542V11.4383Z"
        fill="url(#paint5_linear_299_1525)"
      />
      <path
        opacity="0.2"
        d="M175.336 60.4175C175.336 68.1756 170.763 71.821 165.164 68.643C159.566 65.3715 155.087 56.4917 155.087 48.7335C155.087 40.9753 159.659 37.3299 165.258 40.508C170.763 43.7795 175.336 52.6593 175.336 60.4175Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M331.449 366.164L490.921 458.42L490.828 841L331.355 748.743L331.449 366.164Z"
        fill="url(#paint6_linear_299_1525)"
      />
      <path
        d="M392.196 477.488L519.009 550.77V585.916L392.103 512.634L392.196 477.488Z"
        fill="#7411C1"
      />
      <path
        opacity="0.15"
        d="M351.138 513.849L473.098 584.42V639.101L351.138 568.53V513.849Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M350.951 591.991L473.005 662.562V675.275L350.951 604.797V591.991Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M350.951 614.985L456.488 676.022V688.734L350.951 627.697V614.985Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M331.449 366.164L490.921 458.42V509.736L331.449 417.48V366.164Z"
        fill="url(#paint7_linear_299_1525)"
      />
      <path
        opacity="0.2"
        d="M371.293 415.236C371.293 422.994 366.721 426.64 361.122 423.462C355.523 420.19 351.044 411.31 351.044 403.552C351.044 395.794 355.617 392.149 361.216 395.327C366.721 398.505 371.293 407.478 371.293 415.236Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M303.828 419.162L26.2212 258.671L26.3145 403.646L279.473 550.022L304.015 586.383L303.828 419.162Z"
        fill="url(#paint8_linear_299_1525)"
      />
      <path
        opacity="0.15"
        d="M232.443 413.18L171.603 378.034V390.747L232.443 425.892V413.18Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M232.443 438.698L81.8359 351.582V364.294L232.537 451.41L232.443 438.698Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M232.537 464.215L50.6694 359.06V371.772L232.537 476.927V464.215Z"
        fill="white"
      />
      <path
        d="M247.467 435.146C247.467 447.11 254.559 460.85 263.237 465.898C271.915 470.945 279.007 465.337 279.007 453.373C279.007 441.408 271.915 427.668 263.237 422.62C254.465 417.573 247.467 423.181 247.467 435.146Z"
        fill="#7411C1"
      />
      <path
        opacity="0.15"
        d="M53.6553 156.88L16.8899 135.569V123.137L53.6553 144.449C56.6413 146.225 61.027 149.683 64.7596 155.104C68.772 160.9 71.6647 168.19 71.6647 176.135C71.6647 184.922 69.4252 189.876 65.2261 191.371C61.2137 192.68 56.548 190.437 53.6553 188.754L38.5386 179.968L38.4452 179.874C37.1389 179.127 35.086 178.379 33.4996 178.846C32.1933 179.22 30.6069 180.622 30.6069 185.483C30.6069 190.343 32.0999 193.334 33.4063 195.11C34.9927 197.354 37.0455 198.943 38.3519 199.597L38.5386 199.69L84.8219 226.423V238.855L38.8185 212.216C35.6458 210.533 31.0735 207.075 27.1543 201.56C22.8619 195.484 19.8759 187.819 19.8759 179.22C19.8759 170.714 22.8619 166.321 27.061 165.106C30.9802 163.984 35.5525 165.667 38.7252 167.536L53.7486 176.229C55.8948 177.444 57.5745 178.005 58.6009 177.631C59.4407 177.351 60.9337 176.135 60.9337 169.873C60.9337 165.76 59.6273 163.143 58.321 161.273C56.8279 159.217 54.8684 157.535 53.6553 156.88Z"
        fill="#D30DA8"
      />
      <path
        d="M27.8073 136.877C27.8073 147.533 21.5554 152.487 13.9037 148.094C6.25199 143.701 0 131.456 0 120.8C0 110.144 6.25199 105.19 13.9037 109.584C21.5554 114.07 27.8073 126.315 27.8073 136.877Z"
        fill="#7411C1"
      />
      <path
        d="M101.525 239.696C101.525 250.352 95.2729 255.306 87.6212 250.913C79.9695 246.52 73.7175 234.275 73.7175 223.619C73.7175 212.963 79.9695 208.009 87.6212 212.402C95.2729 216.889 101.525 229.134 101.525 239.696Z"
        fill="#7411C1"
      />
      <path
        opacity="0.2"
        d="M200.717 564.698C206.316 567.969 210.795 575.727 210.795 582.27V610.779L229.644 621.622C235.243 624.893 239.722 632.651 239.722 639.194C239.722 645.644 235.15 648.261 229.644 645.083L210.795 634.241V662.749C210.795 669.199 206.223 671.816 200.717 668.638C195.118 665.367 190.639 657.608 190.639 651.065V622.557L171.79 611.714C166.191 608.442 161.712 600.684 161.712 594.141C161.712 587.692 166.284 585.074 171.79 588.252L190.639 599.095V570.586C190.639 564.043 195.118 561.426 200.717 564.698Z"
        fill="url(#paint9_linear_299_1525)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_299_1525"
          x1="739.614"
          y1="68.3798"
          x2="508.888"
          y2="467.584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_299_1525"
          x1="628.956"
          y1="2.69568"
          x2="398.23"
          y2="401.899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_299_1525"
          x1="702.289"
          y1="96.4214"
          x2="471.563"
          y2="495.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_299_1525"
          x1="591.63"
          y1="30.737"
          x2="360.905"
          y2="429.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_299_1525"
          x1="297.857"
          y1="105.334"
          x2="145.117"
          y2="369.506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_299_1525"
          x1="190.207"
          y1="12.2194"
          x2="238.888"
          y2="150.017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_299_1525"
          x1="493.807"
          y1="460.082"
          x2="341.069"
          y2="724.255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_299_1525"
          x1="386.157"
          y1="366.968"
          x2="434.839"
          y2="504.766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_299_1525"
          x1="243.298"
          y1="361.146"
          x2="99.7362"
          y2="473.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_299_1525"
          x1="220.181"
          y1="601.116"
          x2="171.248"
          y2="639.644"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
