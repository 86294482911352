import "./customImage.component.css";

const CustomImage = ({ ...props }) => {
  return (
    <div className="customImageMD">
      <img {...props} className="customImageMD" />
    </div>
  );
};
export default CustomImage;
