import "./interested.section.css";

import { FC } from "react";
// import { Link } from "react-router-dom";

export const InterestedSection: FC = () => {
  return (
    <section className="interested" id="interested">
      <div className="interested__box">
        <div className="interested__box__description">
          <h2 className="interested__box__text">
            Ready to start working on your project?
          </h2>
          <h3 className="interested__box__text__secound">
            It's simple, send me email and I'll get back to you as soon as
            possible. I'll send you a dedicated quote once we've agreed on a
            specification.
          </h3>
        </div>
        {/* <Link to="/contact" className="interested__box__button">
          Contact
        </Link> */}
        <a
          className="interested__box__button"
          href="mailto:lukols.dev@gmail.com?subject = Contact&body = Contact Message"
        >
          Contact
        </a>
      </div>
    </section>
  );
};
